<template>
      <Catalog @new="newEntity" v-model="entity" :validate="validate" name="Sub Grupo" :rules="rules" :headers="headers" :inputs="inputs" />
</template>
<script>
import { InventorySubGroup } from '../../../models/inventarios/InventorySubGroup';
import { InventoryGroup } from '../../../models/inventarios/InventoryGroup';
import Session from "../../../mixins/sessionMixin";
import { HeaderGrid, Rule, CatalogInput, ValidateForm } from '../../../utilities/General';
import Catalog from "../../../components/templates/BasicCatalog.vue";

export default {
    data() {
    return {
      group: [],
      entity: new InventorySubGroup(),
      inputs: [
        new CatalogInput("Nombre", "name", "text", {cols: 6}),
        new CatalogInput("Clave", "key_name", "text", {cols: 6}),
        new CatalogInput("Grupo", "id_inventory_group", "dropdown", { options: [], options_value: 'id', options_label: 'name', cols: 4}),
      ],
      rules: [
        new Rule({ name: "name" }),
        new Rule({ name: "key_name" })
      ],
      headers: [
        new HeaderGrid("Nombre", "name"),
        new HeaderGrid("Clave", "key_name")
      ],
      validate: new ValidateForm()
    }
  },
  mixins: [Session],
  components: { Catalog },
  methods: {
    newEntity() {
      this.entity = new InventorySubGroup(this.session); 
      this.validate.clear();
    },
  },
  async created() {
    this.validate = new ValidateForm(this.rules);
    this.entity = new InventorySubGroup(this.session); 
    this.group = await new InventoryGroup(this.session).all();
    this.inputs[2].options = this.group;
  }
};
</script> 
 
<style scoped lang="scss">
</style > 
  
